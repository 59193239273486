$(document).ready(function () {
    updateItemList();

    $('#select-all-tags').on('click', function (e) {
        $('.tag').addClass('enabled');

        updateItemList();
    })

    $('#deselect-all-tags').on('click', function (e) {
        $('.tag').removeClass('enabled');

        updateItemList();
    })

    $('.tag').on('click', function (e) {
        $(this).toggleClass('enabled');

        updateItemList();
    })

    function updateItemList() {
        let selectedTags = getEnabledTags();

        let countItem = 0;
        let countHiddenItem = 0;
        $('.item-list').each(function () {
            let item = $(this);
            countItem++;
            let itemTags = item.data('tags');

            if (selectedTags.length > 0) {
                if ($(itemTags).filter(selectedTags).length) {
                    item.show();
                } else {
                    countHiddenItem++
                    item.hide();
                }
            } else {
                item.show();
            }
        });

        let itemListNoResultFound = $('#item_list_no_result_found');
        if (countHiddenItem === countItem) {
            itemListNoResultFound.show();
        } else {
            itemListNoResultFound.hide();
        }

        if (selectedTags.length > 0) {
            $('#deselect-all-tags').show();
        } else {
            $('#deselect-all-tags').hide();
        }
    }

    function getEnabledTags() {
        let tags = [];

        $('.tag.enabled').each(function () {
            tags.push($(this).data('tag-id'))
        });

        return tags;
    }

    $(window).resize(function () {
        recalculateTags();
    });
    recalculateTags();

    function recalculateTags() {

        let $categoriesList = $('.tag-block__list');
        let $categoriesMoreButton = $('.tag-block__btn-bar');
        let $categoriesItem = $('.tag-block__list .tag-item');
        let $categoriesItemSub = $('.tag-block__list-hidden .tag-item');

        $categoriesMoreButton.removeClass('active').hide();
        $categoriesItemSub.hide();
        $categoriesItem.show();

        do {
            var childrenWidth = 0;
            $categoriesList.children().filter(':visible').each(function () {
                childrenWidth += $(this).outerWidth() + 8;
            });

            // NB use extra 10 pixels for padding etc.
            if ($categoriesList.width() > (childrenWidth + 8)) break;

            var $lastVisibleButton = $categoriesItem.filter(':visible').last();
            $categoriesMoreButton.addClass('active').show();
            //console.log($categoriesItemSub.filter('[data-tag-id=' + $lastVisibleButton.data('tag-id') + ']'));
            $categoriesItemSub.filter('[data-tag-id=' + $lastVisibleButton.data('tag-id') + ']').show();
            $lastVisibleButton.hide();

        } while ($categoriesItem.filter(':visible').length);
    }
});